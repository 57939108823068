import React from "react";
import Residencial from "../../Icons/residencial.png";
import style from "./style.css";
import BannerTextComponent from "../../Components/BannerTextComponent";
import GalleryComponent from "../../Components/GalleryComponent";
import residencial from "../../Icons/residencial.jpg";
import residencial2 from "../../Icons/378.jpg";
import residencial3 from "../../Icons/residencial3.jpg";
import residencial4 from "../../Icons/papas.jpg";
import residencial5 from "../../Icons/comercial6.jpg";
import residencial6 from "../../Icons/pirmastore.jpg";

export default class ResidencialScene extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div>
          <div
            className="imgHeader"
            style={{
              width: "100%",
              height: "800px",
              backgroundImage: `url(${Residencial})`,
            }}
          >
            <div
              className="center"
              style={{ fontSize: "75px", color: "white" }}
            >
              <b>Residencial</b>
            </div>
          </div>
        </div>
        <BannerTextComponent
          background="whitesmoke"
          fontColor="gray"
          fontSize="45"
          height="200"
          title="Nuestros Proyectos"
        />
        <GalleryComponent
          photo={residencial}
          photo2={residencial2}
          photo3={residencial3}
          photo4={residencial}
          photo5={residencial}
          photo6={residencial}
        />
        <BannerTextComponent
          background="whitesmoke"
          fontColor="gray"
          fontSize="45"
          height="200"
        />
      </>
    );
  }
}
