import React from "react";
import HeaderComponent from "../../Components/HeaderComponent";
import BannerComponent from "../../Components/BannerComponent";
import ProductsComponent from "../../Components/ProductsComponent";
import CustomersComponent from "../../Components/CustomersComponent";
import mantenimiento from "../../Icons/mantenimiento.jpg";
import { Helmet } from "react-helmet-async";

export default function MainScene(props) {
  return (
    <>
      <Helmet>
        <link rel="icon" href="%PUBLIC_URL%/logo192.png" />
        <title>Cortinas De Acero Domsa</title>
        <meta
          name="Description"
          content="Fabricacion,venta y reparacion de cortinas de acero en leon guanajuato y todo la republica Mexicana."
        ></meta>
        <link rel="canonical" href="/" />
      </Helmet>
      <HeaderComponent />
      <ProductsComponent />
      <BannerComponent />
      <CustomersComponent />
      <div
        className={"bannerCls imgBack"}
        style={{
          opacity: "1",
          height: "600px",
          backgroundImage: `url(${mantenimiento})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="centered-div">
          <div className={"titleArticle"}>
            <div style={{ width: "100%" }}>
              <p>
                <b>Personal</b>
              </p>
            </div>
            <div className={"BannerTitle"}> </div>
          </div>
          <div className={"articleText"}>
            Nuestro personal cuenta con herramientas modernas y especializadas
            para elaborar nuestros productos asi como certificaciones para
            realizar trabajo en alturas utilizando plataformas.
          </div>
        </div>
      </div>
    </>
  );
}
