import React from "react";
import Industrial from "../../Icons/industrial5.jpg";
import BannerTextComponent from "../../Components/BannerTextComponent";
import style from "./style.css";
import GalleryComponent from "../../Components/GalleryComponent";
import Industrial22 from "../../Icons/liverpool.jpg";
import Industrial77 from "../../Icons/nissancortina.jpg";
import industrial2 from "../../Icons/indsutrial2.jpg";
import industrial3 from "../../Icons/industrial3.jpg";
import industrial4 from "../../Icons/industrial5.jpg";
import industrial5 from "../../Icons/industrial7.jpg";

export default class ResidencialScene extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div>
          <div
            className="imgHeader"
            style={{
              width: "100%",
              height: "800px",
              backgroundImage: `url(${Industrial})`,
            }}
          >
            <div
              className="center"
              style={{ fontSize: "75px", color: "white" }}
            >
              <b>Industrial</b>
            </div>
          </div>
        </div>
        <BannerTextComponent
          background="whitesmoke"
          fontColor="gray"
          fontSize="45"
          height="200"
          title="Nuestros Proyectos"
        />
        <div>
          <GalleryComponent
            photo={Industrial22}
            photo2={industrial2}
            photo3={industrial3}
            photo4={industrial4}
            photo5={industrial5}
            photo6={Industrial77}
          />
        </div>
        <BannerTextComponent
          background="whitesmoke"
          fontColor="gray"
          fontSize="45"
          height="200"
        />
      </>
    );
  }
}