import React from 'react'
import Comercial from "../../Icons/burgerking.jpg";
import BannerTextComponent from "../../Components/BannerTextComponent";
import style from "./style.css";
import GalleryComponent from "../../Components/GalleryComponent";
import comercial from "../../Icons/comercial.jpg";
import comercial2 from "../../Icons/sonoras.jpg";
import comercial3 from "../../Icons/gonvil.jpg";
import comercial4 from "../../Icons/papas.jpg";
import comercial5 from "../../Icons/comercial6.jpg";
import comercial6 from "../../Icons/pirmastore.jpg";

export default class ResidencialScene extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<>
            <div>
                <div className="imgHeader" style={{ width: "100%", height: "800px", backgroundImage: `url(${Comercial})` }} >
                    <div className="center" style={{ fontSize: "75px", color: "white" }}>
                        <b>
                            Comercial
                        </b>
                    </div>
                </div>
            </div>
            <BannerTextComponent background="whitesmoke" fontColor="gray" fontSize="45" height="200"  title="Nuestros Proyectos"/>
            <GalleryComponent photo={comercial} photo2={comercial2} photo3={comercial3} photo4={comercial4} photo5={comercial5} photo6={comercial6}/>
            <BannerTextComponent background="whitesmoke" fontColor="gray" fontSize="45" height="200" title="Contáctanos y adquiere la mejor calidad"/>

        </>)
    }
}
