import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import MainScene from '../src/Scenes/MainScene';
import ResidencialScene from '../src/Scenes/ResidencialScene';
import IndustrialScene from '../src/Scenes/IndustrialScene';
import ComercialScene from '../src/Scenes/ComercialScene';
import NavigationBarComponent from './Components/NavigationBarComponent';
import FooterComponent from './Components/FooterComponent';
import ManteinensScene from './Scenes/ManteinensScene';
import MaterialSalesScene from './Scenes/MaterialSalesScene';
import CortinasDeAceroScene from './Scenes/CortinasDeAceroScene';
import AutomatizacionesScene from './Scenes/AutomatizacionesScene';
import ContactScene from './Scenes/ContactScene/intdex';
import FloatingWhatsApp from 'react-floating-whatsapp';
import avatar from '../src/Icons/domsaIcon.jpg'
import { HelmetProvider } from 'react-helmet-async';

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <div className="App">
          <NavigationBarComponent />
          <Routes>
            <Route exact path='/' element={<MainScene />} />
            <Route exact path='/industrial' element={<IndustrialScene />} />
            <Route exact path='/residencial' element={<ResidencialScene />} />
            <Route exact path='/comercial' element={<ComercialScene />} />
            <Route exact path='/mantenimiento' element={<ManteinensScene />} />
            <Route exact path='/materiales' element={<MaterialSalesScene />} />
            <Route exact path='/cortinas' element={<CortinasDeAceroScene />} />
            <Route exact path='/automatizaciones' element={<AutomatizacionesScene />} />
            <Route exact path='/contactanos' element={<ContactScene />} />
            <Route path="*" element={<MainScene />} />
          </Routes>
          <FooterComponent />
          <FloatingWhatsApp styles={{ zIndex: "9999999" }}
            phoneNumber="4772349684"
            accountName="Cortinas De Acero Domsa"
            allowClickAway={true}
            notification={true}
            statusMessage={"Listos para atenderte!"}
            avatar={avatar}
            Boolean={true}
            notificationSound={false}
            darkMode={false}
            placeholder={"Escribenos"}
            chatMessage={"Bienvenido en que podemos ayudarte?"}
            notificationDelay={60000} // 1 minute
            notificationSound={true}
          />
        </div>
      </HelmetProvider>
    );
  }
}
export default App;