import React, { useRef, useState } from "react";
import style from './style.css';

export default function CurtainsGalleryComponent(props) {

    const image = [`url(${props.image1})`, `url(${props.image2})`,`url(${props.image3})`,`url(${props.image4})`]
    const delay = parseInt(props.last);

    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === image.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);




    return (
        <>
            <div className="biggestContainer" style={{ height: "auto", display: "table", width: "100%", display:"flex"  }}>
                <div className={"imgStyle"} style={{ display: "table-cell", height: "550px", display:"inline-block" }}>
                    <div className="slideshow">
                        <div
                            className="slideshowSlider"
                            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                        >
                            {image.map((backgroundImage, index) => (
                                <div
                                    className="slide imgStyle"
                                    key={index}
                                    style={{ backgroundImage}}
                                ></div>
                            ))}
                        </div>

                        <div className="slideshowDots">
                            {image.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={`slideshowDot${index === idx ? " active" : ""}`}
                                    onClick={() => {
                                        setIndex(idx);
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={"columnTitles"} style={{ display: "table-cell", backgroundColor: "white", display:"inline-block"  }}>
                    <div style={{ paddingTop: "105px", paddingBottom: "100px", fontSize: "30px", fontWeight: "lighter" }}>
                        <div className="titles"> {props.title1} </div>
                        <div className="titles"> {props.title2} </div>
                        <div className="titles"> {props.title3} </div>
                        <div className="titles"> {props.title4} </div>
                        <div className="titles"> {props.title5} </div>
                        <div className="titles"> {props.title6} </div>
                        <div className="titles"> {props.title7} </div>
                    </div>
                </div>
            </div>
        </>

    )
}
