
import style from './style.css'
export default function CardProductComponent(props) {
    return(
                <div style={{ padding: "10px", borderRadius: "5px", borderStyle: "solid", borderWidth: ".5px", borderColor: "whitesmoke" }}>
                    <div style={{ width: "300px", height: "300px", borderRadius: "5px" }}>
                        <div className={"imgProdutcCard"} style={{ height: "300px", borderRadius: "5px", backgroundImage: `url(${props.image})` }} />
                    </div>
                    <div style={{ width: "300px", backgroundColor: "whitesmoke", padding: "10px", color: "gray", fontWeight: "800" }}>
                        {props.title}
                    </div>
                </div>) 
            
}