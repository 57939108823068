import React from 'react';
import CardComponent from '../CardComponent';
import style from './style.css';
import Europea from '../.././Icons/Europea.png';
import Duela from '../.././Icons/Duela.png';
import DuelaPerforada from '../.././Icons/microperforada.png';
import EuropeaPerforada from '../.././Icons/europeaperforada.png';
import Troquelada from '../.././Icons/troquelada.png';
import Galvanizada from '../.././Icons/galvanizada.png';

export default class ProductsComponent extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(<>
             {/*  <div style={{display:"inline-block", paddingTop:"40px",paddingBottom:"40px"}}>
        <div style={{display:"inline-block",fontSize:"55px",padding:"20px" ,width:"30%",float:"left",top:"0px"}}>
           <div className={"BannerTitleMain"}><b>Productos</b>
           <div className={"BannerTitleProducts"}></div></div>
        </div>
        <div style={{display:"inline-block",width:"60%"}}>*/}
        <div style={{ height: "auto", display: "table" ,width: "100%"}}>  
            
            <div className={"columnArticle"} 
            style={{display: "table-cell",postion:"relative"}}>
                <div style={{
                width:"100%",top:"0px",display:"inline-block",fontSize:"55px",
                top:"20px", postion:"absolute"}}>
                    <div className={"BannerTitleMain"}><b>Productos</b>
                    <div className={"BannerTitleProducts"}></div></div>
                </div>
            </div> 

            <div className={"columnArticle"}  style={{display: "table-cell"}}>
                    <div style={{paddingTop:"8px",paddingRight:"8px",display:"inline-block"}}>
                        <CardComponent imgUrl={Duela} title="Duela"/>
                    </div>
                    <div style={{paddingTop:"8px",paddingRight:"8px",display:"inline-block"}}>
                        <CardComponent imgUrl={Europea} title="Europea"/>
                    </div>
                    <div style={{paddingTop:"8px",paddingRight:"8px",display:"inline-block"}}>
                        <CardComponent imgUrl={DuelaPerforada} title="Multiperforada"/>
                    </div>
                    <div style={{paddingTop:"8px",paddingRight:"8px",display:"inline-block"}}>
                        <CardComponent imgUrl={EuropeaPerforada} title="Multiperforada"/>
                    </div>
                    <div style={{paddingTop:"8px",paddingRight:"8px",display:"inline-block"}}>
                        <CardComponent imgUrl={Troquelada} title="Troquelada"/>
                    </div>
                    <div style={{paddingTop:"8px",paddingRight:"8px",display:"inline-block"}}>
                        <CardComponent imgUrl={Galvanizada} title="Galvanizada"/>
                    </div>
            </div> 
        </div>
       {/*  </div>
       </div>*/}
        </>)
    }
}