import React from 'react';
import Automatizada from "../../Icons/automatizada.jpg";
import bannerImg from "../../Icons/lasalle.jpg";
import BannerTextComponent from "../../Components/BannerTextComponent";
import { IGEmbed } from 'react-ig-embed';
import style from "./style.css";

export default class AutomatizacionesScene extends React.Component {
  constructor(props) {
      super(props)
  }

  render() {
      return (<>
          <div>
              <div className="imgHeader" style={{ width: "100%", height: "1050px", backgroundImage: `url(${Automatizada})` }} >
                  <div className="center automationTitle">
                      <b>
                          Automatizaciones
                      </b>
                  </div>
              </div>
          </div>
          <BannerTextComponent background="whitesmoke" fontColor="gray" fontSize="40" height="150"  title="Implementamos solo las mejores marcas de motores"/>
          <div style={{ height: "550px", display: "table" ,width: "100%"}}>
              <div className={"imgHeader columnArticle"} style={{height: "550px",display: "table-cell",backgroundImage: `url(${bannerImg})`}}>
              </div>
              <div className={"columnArticle"} style={{ display: "table-cell",backgroundColor:"white"}}>
              <div className={"services-list"}>
                      <div>
                          Automatizacion de cortinas de impulso.
                      </div>
                      <div>
                          Automatizacion de cortinas de cadena.
                      </div>
                      <div>
                          Espacilizados en cortinas extra grandes.
                      </div>
                      <div>
                          Venta de motores y controles.
                      </div>
                  </div>
              </div>
          </div>
          <BannerTextComponent background="#6b9ebd" fontColor="white" fontSize="45" height="200"  title="Obten el presupuesto para tu proyecto!"/>
          <IGEmbed spinnerDisabled={true} linkText="Cortinas De Acero Domsa" url="https://www.instagram.com/p/CVia2uugr_K/" />
          <BannerTextComponent background="whitesmoke" fontColor="gray" fontSize="40" height="150"  title="Inversion a largo plazo sin reparaciones prematuras."/>
      </>)
  }
  
}