import { useNavigate } from 'react-router-dom';
import style from './style.css';

export default function ButtonComponent(props) {
    let navigate = useNavigate();
    function navigateTo(option) {
        if (option == 'Industrial') {
            navigate('/industrial')
            props.hanlderClickOnOption();
        } else if (option == 'Residencial') {
            navigate('/residencial');
            props.hanlderClickOnOption();
        } else if (option == 'Comercial') {
            navigate('/comercial');
            props.hanlderClickOnOption();
        }
        else if (option == 'Mantenimiento') {
            navigate('/mantenimiento');
            props.hanlderClickOnOption();
        } 
        else if(option == 'Venta De Materiales'){
            navigate('/materiales')
            props.hanlderClickOnOption();
        }
        else if(option == 'Cortinas De Acero'){
            props.hanlderClickOnOption();
            navigate('/cortinas')
        }
        else if(option == 'Automatizaciones'){
            navigate('/automatizaciones')
            props.hanlderClickOnOption();
        }
        else if(option == 'Contacto'){
            navigate('/contactanos')
        }
        else {
            navigate('/')
        }
    }

    return (<div onClick={() => navigateTo(props.option)} className="optionCls">
        {props.option}
    </div>
    );
};