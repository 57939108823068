import React from 'react';
import Pirma from '../.././Icons/pirma.png';
import Burger from '../.././Icons/burgerking.png';
import Nissan from '../.././Icons/nissan.png';
import Toyota from '../.././Icons/toyota.png';
import style from './style.css';

export default class CustomersComponent extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(<>
            <div className={"customer-container"}>
                <div style={{padding:"20px", fontSize:"40px"}}>
                    <label style={{borderBottom:"10px solid #6B9EBD"}}>
                        <b>Clientes</b>
                    </label>
                </div>

                <div style={{padding:"30px"}}>
                    <img className={"toyotaCls"} src={Toyota} />
                    <img className={"PirmaCls"} src={Pirma} />
                    <img className={"BurgerCls"} src={Burger} />
                    <img className={"NissanCls"} src={Nissan} />
                </div>
            </div>
        </>)
    }
    
}
