import React from 'react'
import phoneLogo from '../../Icons/phone.png';
import emailLogo from '../../Icons/email.png';
import locationLogo from '../../Icons/location.png';
import style from './style.css'

export default class FooterComponent extends React.Component{
    constructor(props){
        super(props)
    }

    handlerFacebook = () => {
        window.open("https://www.facebook.com/CortinasDeAceroDomsa");
    }

    handlerInstagram = () => {
        window.open("https://www.instagram.com/cortinas_domsa/");
    }

    render(){
        return(
        <>
            <footer class="page-footer font-small blue pt-4 foote-wrapper">
            <div class="container-fluid text-center text-md-left" style={{marginTop:"80px"}}>
                <div class="row">
                <div class="col-md-6 mt-md-0 mt-3" style={{position:"relative"}}>
                    <h5 class="text-uppercase" style={{fontSize:"45px",color:"#32C3FF",fontStyle:"italic"}}>Domsa</h5>
                    <p style={{fontSize:"25px",position:"relative",top:"-15px",fontStyle:"italic"}}>Cortinas De Acero</p>
                    <p style={{textAlign:"center"}}>
                        Somos una empresa Mexicana con presencia en todo la republica, 
                        la calidad es lo que nos distingue en la fabricación ya que todos los componentes son 
                        seleccionados y fabricados con los mejores estandares del sector.
                    </p>
                </div>
                <div class="col-md-3 mb-md-0 mb-3"> 
                    <h5 style={{fontSize:"24px"}}>Redes Sociales</h5>
                    <ul class="list-unstyled" style={{fontSize:"16px"}}>
                    <li style={{padding:"5px"}}>
                        <div className="labels" > 
                           <label onClick={() => {this.handlerInstagram()}}>Instagram</label>
                        </div>
                    </li>
                    <li style={{padding:"5px"}}>
                        <div className="labels">
                            <label onClick={() => {this.handlerFacebook()}}>Facebook</label> 
                        </div>
                    </li>
                    <li style={{padding:"5px"}}>
                        <div className="labels">Tik tok</div>
                    </li>
                    </ul>
                </div>
                <div class="col-md-3 mb-md-0 mb-3">
                    <h5 style={{fontSize:"24px"}}>Contactanos</h5>

                    <ul class="list-unstyled" style={{fontSize:"14px"}}>
                    <li style={{padding:"5px"}}>
                        <div className="labels"><img src={phoneLogo}  style={{height:"20px"}}/>(477)-636-09-48</div>
                    </li>
                    <li style={{padding:"5px"}}>
                    <div className="labels"><img src={phoneLogo}  style={{height:"20px"}}/>(477)-636-09-41</div>
                    </li>
                    <li style={{padding:"5px"}}>
                        <div className="labels">
                            <img src={emailLogo}  style={{height:"20px"}}/>
                            cortinasacero_domsa@yahoo.com.mx
                        </div>
                    </li>
                    <li style={{padding:"5px"}}>
                        <div className="labels">
                            <img src={locationLogo}  style={{height:"20px"}}/>
                            Leon Gto, MX.
                            </div>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </footer>
            <div style={{height:"50px", backgroundColor:"#283139",color:"white", fontSize:"13px",textAlign:"center",padding:"10px" }}>
                <b>Cortinas De Acero Domsa © 2022.</b>
            </div>
        </>)
    }
}