import React from "react";
import mantenimiento from '../../Icons/mantenimiento.jpg';
import style from './style.css';
import BannerTextComponent from '../../Components/BannerTextComponent';
import CurtainsGalleryComponent from "../../Components/CurtnainsGalleryComponent";
import imagen1 from '../../Icons/maintenance1.jpg';
import imagen2 from '../../Icons/plazatroquelada.jpg';
import imagen3 from '../../Icons/locales6.jpg';
import imagen4 from '../../Icons/comercial3.jpg';

export default function ManteinensScene(){
        return (<>
            <div className="imgHeader topImageCls" style={{height: "800px", backgroundImage: `url(${mantenimiento})` }} >
               <div className="center headerStyleCls">
                    <b>
                     Mantenimiento
                    </b>
                </div>
            </div>
            <BannerTextComponent background="whitesmoke" fontColor="gray" fontSize="40" height="150"  title="Agenda tu mantenimiento"/>
            <div style={{ height: "auto" }}>
            <CurtainsGalleryComponent image1={imagen1} image2={imagen2} image3={imagen3} image4={imagen4}
            title1="Mantenimiento Preventivo" title2="Cambio de resortes" title3="Reparación de flechas de cadena" title4="Aseguramiento de cortinas"
             title5="Remplazo de tiras" title6="Batientes" title7="Postigos" last="3600"/>
            </div>
            <BannerTextComponent background="#6b9ebd" fontColor="white" fontSize="45" height="200"  title="La Calidad no nace se hace!"/>
        </>)
    }
