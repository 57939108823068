import React from "react";
import style from './Style.css'
import ButtonComponent from '../ButtonComponent'
import LabelComponent from "../LabelComponent";
export default class DropDownCompomnent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            isDropDown: this.props.isDropDown
        }
    }

    handlerOnMouseOver = () => {
        if(this.state.isDropDown){
            this.setState({ open: true});
        }
    };

    handlerOnMouseLeave = () => {
        this.setState({ open: false });
    };

    hanlderClickOnOption = () => {
        if(this.props.title != 'Contacto' && this.props.title != 'Inicio'){
          this.setState({ open: !this.state.open});  
        }
    }

    render() {
        return (
            <div onMouseLeave={this.handlerOnMouseLeave} style={{ textAlign: "left" , position:"relative"}}>
                <div className="titleLabel" style={{ color: "white", padding: "10px"}}>
                    <label className="titleLabel" onclick={() => {this.hanlderClickOnOption()}}  onMouseOver={() => this.handlerOnMouseOver()}>
                        <LabelComponent hanlderClickOnOption={this.hanlderClickOnOption} tittle={this.props.title} />
                    </label>
                </div>
                {(this.state.open) ? this.renderOptions() : <></>}
            </div>)
    }

    renderOptions() {
        let options = this.props.categories;
        let categoryOption = options.map((item, index) => {
            return <ButtonComponent hanlderClickOnOption={this.hanlderClickOnOption} option={item}/>
        });
        return (
            <div style={{position:"absolute",top:"0px;"}} className="OptionLists">
                {categoryOption}
            </div>
        )
    }
}