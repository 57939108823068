import React from 'react';
import style from './style.css';

export default class CardComponent extends React.Component{
    constructor(props){
        super(props);
    }

    handlerOnClick = (event) => {
        let option = event.target.getAttribute("data-atributte-option");
        this.props.handlerOnClickOptions(option);
    }

    render(){
        return(<>
        <div className={"maincontainer"}>
            <div className={"thecard"}>
                <div style={{backgroundImage:`url(${this.props.imgUrl})`}}  className={"thefront"}>
                </div>
                <div onClick={this.handlerOnClick} style={{backgroundImage:`url(${this.props.imgUrl})`}}  data-atributte-option={this.props.title} className={"theback"}>
                    <b className='names'>{this.props.title}</b>
                </div>
            </div>
        </div>
        </>)
    }
}