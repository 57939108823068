import style from './style.css';

export default function BannerTextComponent(props) {
    return (<>
        <div className="bannerContainer" style={{ color:`${props.fontColor}`,fontSize: `${props.fontSize}px`, height: `${props.height}px`, backgroundColor: `${props.background}` }}>
            <div className="bannerContent">
                <b>{props.title}</b>
            </div>
        </div>
    </>)
}