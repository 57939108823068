import React from 'react';
import style from './style.css';

export default class BannerComponent extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(<>
        <div className={"bannerCls"}>
            <div className="centered-div">
                <div className={"titleArticle"}>
                    <div style={{width:"100%"}}>
                       <p><b>Acerca de Nosotros</b></p>
                    </div>
                    <div className={"BannerTitle"}></div>
                </div>
                <div className={"articleText"}>
                      Cortinas de acero domsa es una empresa mexicana con mas de 40 año de experiencia 
                      en la industria de las cortinas de acero, especializandoce en cortinas grandes y extra grandes de uso
                      industrial, comercial, Resicencial. 
                      Hemos bridando las mejores soluciones aplicando los standares de la mas alta calidad en nuestros productos.
                </div>
            </div>
        </div>
        </>)
    }
}