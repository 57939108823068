import React from 'react';
import {Helmet} from 'react-helmet'
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"; //import react router dom
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'; //import bootstrap
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDabqJaqXCcWXXF65ExFQzqUTiUgfVD__o",
  authDomain: "website-5e9ac.firebaseapp.com",
  projectId: "website-5e9ac",
  storageBucket: "website-5e9ac.appspot.com",
  messagingSenderId: "410913544528",
  appId: "1:410913544528:web:913ad6197c608659823455"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
ReactDOM.render(
  <React.StrictMode>
      <title>Cortinas De Acero Domsa</title>
    <meta name="Description" content="Fabricacion,venta y reparacion de cortinas de acero en leon guanajuato y todo la republica Mexicana."/>
    <meta name="description" content="En Cortinas de Acero Domsa fabricamos e instalamos las cortinas metálicas que requiera para su negocio en León y toda la República Mexicana."></meta>
    <meta name="twitter:description" content="En Cortinas de Acero Domsa fabricamos e instalamos las cortinas metálicas que requiera para su negocio en León y toda la República Mexicana."></meta>
    <meta property="og:url" content="https://www.cortinasacerodomsa.com/"></meta>
    <meta property="og:type" content="website"></meta>
    <meta property="og:site_name" content="CORTINAS DE ACERO DOMSA"></meta>
    <meta property="og:description" content="En Cortinas de Acero Domsa fabricamos e instalamos las cortinas metálicas que requiera para su negocio en León y toda la República Mexicana."></meta>
    <meta name="google-site-verification" content="iUoptnUeiF03av8GzE9riAhMdOLJ-sxs6srEwS7Fzr4"></meta>

    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
