import DropDownCompomnent from '../DropDownComponent';
import style from './style.css';
export default function NavigationBarComponent(props) {
        return (
        <div style={{ width: "100%",height: "45px", zIndex:"999999", position: 'absolute'}}>
           
            <div className={"NavCompanyName"}>
                <b style={{fontStyle:"italic"}}>
                    <div style={{color:"#32C3FF",fontSize:"60px"}}>
                        DOMSA
                    </div>
                    <div style={{color:"white",fontSize:"20px",position:"relative", top:"-16px"}}> 
                        Cortinas De Acero
                    </div>
                </b>
            </div>

            <div className={"NavOptionsCls"}>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <DropDownCompomnent isDropDown={false} title="Inicio" />
                </div>
                <div style={{ width: "100px", display: "inline-block"}}>
                    <DropDownCompomnent isDropDown={true} title="Servicios" categories={['Cortinas De Acero', 'Mantenimiento','Venta De Materiales','Automatizaciones']} />
                </div>
                <div style={{ width: "100px", display: "inline-block"}}>
                    <DropDownCompomnent isDropDown={true} title="Galeria" categories={['Industrial', 'Residencial','Comercial']} />
                </div>
                <div style={{ width: "100px", display: "inline-block"}}>
                    <DropDownCompomnent isDropDown={false} title="Contacto" />
                </div>
            </div>
        </div>)
}