import React from 'react'
import NavigationBarComponent from '../NavigationBarComponent';
import CarouselComponent from '../CarouselComponent';

export default class HeaderComponent extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
        <div>
            <div style={{width:"100%"}}>
                <div style={{ position: "", width: "100%", top: "0px" }}>
                    <CarouselComponent />
                </div>
            </div>
        </div>)
    }
}