import style from './style.css';

export default function ContactUsComponent(props) {
    return (
           <div class="container" style={{ paddingTop: "70px" }}>
            <div class="row" >
                <div class="col">
                    <div className='dataContact'>
                        <h3> <b style={{ fontWeight: "800" }}>CONTACTOS</b></h3>
                        <div style={{ paddingTop: "60px",/*display: "inline-blocks"*/ }}>
                            <div>
                                <div style={{ display: "inline-flex", paddingRight: "5px" }}>
                                    <h5 style={{ display: "inline-block" }}>Horarios:</h5><p style={{ display: "inline-block", marginLeft: "5px", fontSize: "17px" }}> Lun-Vie: 8am-6pm <br />Sab: 8am-2:30pm</p>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: "inline-flex" }}>
                                    <h5 style={{ display: "inline-block" }}>Teléfonos:</h5> <p style={{ display: "inline-block", marginLeft: "5px" }}> (477)-636-09-48 <br />(477)-636-09-41</p>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: "inline-flex" }}>
                                    <h5 style={{ display: "inline-block" }}>Correo:</h5> <p style={{ display: "inline-block", marginLeft: "5px" }}> <a href="cortinasacero_domsa@yahoo.com.mx">cortinasacero_domsa@yahoo.com.mx</a> </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="columForm col-8">
                    <div className="formatContainer">
                        <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                            <input type="hidden" name="oid" value="00D5f000009lHrv" />
                            <input type="hidden" name="retURL" value="https://website-5e9ac.web.app" />
                            <div>
                                <h2><b style={{ fontWeight: "800" }}>Contáctate Con Nosotros</b></h2>
                            </div>
                            <div className='items'>
                                <div className="element">
                                    <input className={"inputCls"} placeholder='Nombre:' id="first_name" maxlength="40" name="first_name" size="50" type="text" /><br />
                                </div>
                                <div className="element">
                                    <input className={"inputCls"} placeholder='Apellidos:' input id="last_name" maxlength="80" name="last_name" size="50" type="text" /><br />
                                </div>
                                <div className="element">
                                    <input className={"inputCls"} placeholder='Email:' id="email" maxlength="80" name="email" size="50" type="text" /><br />
                                </div>
                                <div className="element">
                                    <label for="company"></label><input className={"inputCls"} placeholder='Compañía:' id="company" maxlength="40" name="company" size="50" type="text" /><br />
                                </div>
                                <div className="element">
                                    <label for="city"></label><input className={"inputCls"} placeholder='Ciudad:' id="city" maxlength="40" name="city" size="50" type="text" /><br />
                                </div>
                                <div className="element">
                                    <label for="state"></label><input className={"inputCls"} placeholder='Estado/Provincia:' id="state" maxlength="20" name="state" size="50" type="text" /><br />
                                </div>
                                <div className='element' style={{ paddingTop: "30px", paddingBottom: "70px" }}>
                                    <input className="elementSubmit" type="submit" name="submit" value={"SEND"} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}