import React from "react";
import style from './style.css'

export default class GalleryComponent extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <div class="equalHWrap eqWrap columnArticle" style={{ width: "100%", height: "550px", padding: "50px", paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px" }}>
                    <div class="equalHW imgHeader imgind columnArticle" style={{ backgroundImage: `url(${this.props.photo})` }} />
                    <div class="equalHW imgHeader imgind columnArticle" style={{ backgroundImage: `url(${this.props.photo2})` }} />
                    <div class="equalHW imgHeader imgind columnArticle" style={{ backgroundImage: `url(${this.props.photo3})` }} />
                </div>
                <div class="equalHWrap eqWrap columnArticle" style={{ width: "100%", height: "550px", padding: "50px", paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px" }}>
                    <div class="equalHW imgHeader imgind columnArticle" style={{ backgroundImage: `url(${this.props.photo4})` }} />
                    <div class="equalHW imgHeader imgind columnArticle" style={{ backgroundImage: `url(${this.props.photo5})` }} />
                    <div class="equalHW imgHeader imgind columnArticle" style={{ backgroundImage: `url(${this.props.photo6})` }} />
                </div>
            </>
        )
    }
}