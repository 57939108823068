
import React from "react";
import Cortinas from '../../Icons/flechaamarilla.jpg';
import BannerTextComponent from '../../Components/BannerTextComponent';
import style from './style.css';
import CurtainsGalleryComponent from "../../Components/CurtnainsGalleryComponent";
import img1 from '../../Icons/comercial6.jpg';
import img2 from '../../Icons/locales3.jpg';
import img3 from '../../Icons/comercial.jpg';
import img4 from '../../Icons/flechaamarilla.jpg';

export default class CortinasDeAceroScene extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<>
            <div>
                <div className="imgHeader" style={{ width: "100%", height: "800px", backgroundImage: `url(${Cortinas})` }} >
                    <div className="center" style={{ fontSize: "75px", color: "white" }}>
                        <b>
                            Cortinas de Acero
                        </b>
                    </div>
                </div>
            </div>
            <BannerTextComponent background="whitesmoke" fontColor="gray" fontSize="40" height="150"  title="Contamos con soluciones a tus necesidades"/>
            <div style={{ height: "auto" }}>
            <CurtainsGalleryComponent image1={img3} image2={img2} image3={img1} image4={img4} 
            title1="La mejor calidad a tu alcance" title2="Expertos en cortinas extragrandes" title3="Contamos con toda refacción" title4="Reparación de flechas de cadena"
            title5="Amplio catalogo de lamina y colores" title6="!Visualizala y nosotros la hacemos posible¡" last="3600"/>
            </div>
            <BannerTextComponent background="#6b9ebd" fontColor="white" fontSize="45" height="200"  title="Obten el presupuesto para tu proyecto!"/>
        </>)
    }
}