import React from 'react';
import Comercial from "../../Icons/burgerking.jpg";
import BannerTextComponent from "../../Components/BannerTextComponent";
import style from "./style.css";
import ContactUsComponent from "../../Components/ContactUsComponent";
import ContactUs from "../../Icons/contactus.png";
export default function ContactScene() {
    return (
      <>
        <div>
          <div
            className="imgHeader topImageCls"
            style={{ backgroundImage: `url(${ContactUs})` }}
          >
            <div className="center">
              <b>Contactanos</b>
            </div>
          </div>
        </div>
        <div style={{ height: "auto" }}>
          <ContactUsComponent />
        </div>
        <BannerTextComponent
          background="#6b9ebd"
          fontColor="white"
          fontSize="45"
          height="200"
          title="Obten el presupuesto para tu proyecto!"
        />
      </>
    );
  }