import { useNavigate } from 'react-router-dom';

export default function LabelComponent(props) {
    let navigate = useNavigate();
    function navigateTo(option) {
        if (option == 'Inicio') {
            navigate('/')
        } else if (option == 'Contacto') {
            navigate('/contactanos');
        } 
        props.hanlderClickOnOption();
    }

    return (<b onClick={() => navigateTo(props.tittle)}>
        {props.tittle}
    </b>
    );
};