import laminacinta from "../../Icons/laminacinta.jpg";
import piezalateral from "../../Icons/piezaslaterales.jpg";
import resortes from "../../Icons/resortes.jpg";
import lamina from "../../Icons/Europea.png";
import remaches from "../../Icons/remache.png";
import candena from "../../Icons/cadena.jpg";
import motores from "../../Icons/motor.jpg";
import style from "./style.css";
import BannerTextComponent from "../../Components/BannerTextComponent";
import CardProductComponent from "../../Components/CardProductComponent";

export default function MaterialSalesScene() {
  return (
    <>
      <div>
        <div
          className="imgHeader"
          style={{
            width: "100%",
            height: "800px",
            backgroundImage: `url(${laminacinta})`,
          }}
        >
          <div className="center headerStyleCls">
            <b>Materiales</b>
          </div>
        </div>
      </div>
      <div>
        <BannerTextComponent
          background="whitesmoke"
          fontColor="gray"
          fontSize="40"
          height="150"
          title="Accesorios"
        />
        <div style={{ padding: "50px" }}>
          <div style={{ display: "inline-block", padding: "10px" }}>
            <CardProductComponent
              title={"Pieza Lateral"}
              image={piezalateral}
            />
          </div>
          <div style={{ display: "inline-block", padding: "10px" }}>
            <CardProductComponent title={"Resortes"} image={resortes} />
          </div>
          <div style={{ display: "inline-block", padding: "10px" }}>
            <CardProductComponent title={"Lamina"} image={lamina} />
          </div>
          <div style={{ display: "inline-block", padding: "10px" }}>
            <CardProductComponent title={"Remache"} image={remaches} />
          </div>
          <div style={{ display: "inline-block", padding: "10px" }}>
            <CardProductComponent title={"Cadena"} image={candena} />
          </div>
          <div style={{ display: "inline-block", padding: "10px" }}>
            <CardProductComponent title={"Motores"} image={motores} />
          </div>
        </div>
      </div>
      {/*<div style={{ height: "550px",width:"100%" }}>
                Take in consideration an ecommerce site
                https://www.wix.com/blog/ecommerce/category/dropshipping
        </div>*/}
      <BannerTextComponent
        background="#6b9ebd"
        fontColor="white"
        fontSize="45"
        height="200"
        title="Obten el presupuesto para tu projecto!"
      />
    </>
  );
}
