import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import ComercialImg from '../../Icons/burger.jpg';
import residencial from '../../Icons/slidetwo.jpg';
import liverpool from '../../Icons/liverpool.jpg';
import gonvil from '../../Icons/gonvil.jpg';
import style from './style.css'
export default class CarouselComponent extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return( 
            <Carousel>

            <Carousel.Item>
              <img className="d-block w-100 imageCarousel"
                src={ComercialImg} alt="First slide"/>
              <Carousel.Caption>
              </Carousel.Caption>
            </Carousel.Item>
            
            <Carousel.Item>
              <img className="d-block w-100 imageCarousel"
                src={residencial} alt="Second slide"/>
              <Carousel.Caption>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 imageCarousel"
                src={liverpool} alt="Third slide" />
              <Carousel.Caption>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100 imageCarousel"
                src={gonvil}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        )
    }
}